import React, { useRef } from 'react';
import styled from 'styled-components';
import { AiOutlineArrowRight } from "react-icons/ai";
import { useChat } from '../context/ChatProvider';
import './Login.css';

/* const LoginContainer = styled.form`
    display: flex;
    gap: 0px;
    align-items: center;
    background: #fff;
    padding: 15px 20px;
    border-radius: 100px;
    width: clamp(210px, 18vw, 20%);
    
    & button {
        display: flex;
        padding: 10px 20px;
        border: none;
        border-radius: 100px;
        background: var(--blue-active-color);
        transition: .3s ease-in-out opacity, box-shadow;
        cursor: pointer;

        &:hover {
            opacity: 0.85;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }

    }
`;

const Input = styled.input.attrs(props => ({
        type: 'text'
    }))`

    width: 100%;
    border: none;
    background: transparent;
    color: #2d2173;

    &::placeholder {
        color: #2d2173;
        font-size: 1em;
    }

    @media (max-width: 820px) {
        font-size: 0.7em;
    }
`;

const Login = () => {
    const inputRef = useRef(null);
    const { setUserName } = useChat();

    function handleSubmit(e) {
        e.preventDefault();
        
        setUserName(inputRef.current.value);
    }

    return (
        <LoginContainer onSubmit={ handleSubmit }>
            <Input type="text" placeholder="Enter a username" ref={ inputRef } />
            
            <button>
                <AiOutlineArrowRight color='#fff' size="1.2em" />
            </button>
        </LoginContainer>
    );
}; 

export default Login; */

 const Login = () => {
    const inputRef = useRef(null);
    const { setUserName } = useChat();

    function handleSubmit(e) {
        e.preventDefault();

        setUserName(inputRef.current.value);
    }

    return (
        <div className="container">
            <div className="login-box">
                <h1>Planemo AI</h1>
                <br></br>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input type="text" placeholder="Enter your username" ref={inputRef} />
                        <input type="password" placeholder="Enter your password" ref={inputRef} />
                    </div>
                    <button type="submit" label="Sign In">Sign In
                        <AiOutlineArrowRight color='#fff' size="1.2em" />
                    </button>
                    <div className="links">
          <a href="https://chat.planemo.ai/reset-password">Reset Password</a>
          <br></br>
          <a href="https://planemo.us/support">Support</a>
          <br />
          <a href="https://planemo.us/privacy-policy">Privacy Policy</a>
          <br />
          <a href="https://planemo.us/terms-of-use">Terms of Use</a>
          <br></br>
          <a href="https://planemo.us">&copy; 2023 Planemo LLC</a>
        </div>
                </form>
            </div>
        </div>
    );
};

export default Login; 