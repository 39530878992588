import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { ChatProvider } from './context/ChatProvider';
import Wrapper from './components/Wrapper';

const GlobalStyle = createGlobalStyle`
  :root {
    --main-color-dark-palette: #2d2173;
    --secondry-color-dark-palette: #373737;
    --blue-button-color: #3c95f4;
    --blue-active-color: #2070c6;
    --blue-gradient: linear-gradient(90deg, #2d2173 65%, #2d2173 100%);
  }

  * {
    margin: 0;
    padding: 0;
    outline: transparent;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background-image: url('/images/astronaut.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const Background = styled.div`
position: absolute;
height: 100vh;
width: 100vw;
overflow: hidden;
z-index: -1;
background-image: url('client/public/Images/astronaut.jpg');
background-size: cover;
background-repeat: no-repeat;



  @media (max-width: 820px) {
    &::before, &::after {
      width: 25rem;
      height: 25rem;
    }
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      
      <Background />

      <ChatProvider>  
        <Wrapper />
      </ChatProvider>
    </>
  );
}

export default App;
